import './DataTable.css';

import { default as ReactDataTable } from 'react-data-table-component';
import PropTypes from 'prop-types';

const DataTable = ({
    columns,
    data,
    pagination = true,
    paginationServer = false,
    paginationTotalRows = 0,
    paginationPerPage = 15,
    paginationDefaultPage = 1,
    paginationRowsPerPageOptions = [5, 10, 15, 20, 25, 30],
    onChangeRowsPerPage,
    onChangePage,
    progressPending = false,
    progressComponent = null,
    defaultSortFieldId = 1,
    highlightOnHover = true,
    onRowClicked = null,
    persistTableHead = true,
    headerBackground = true,
    className = '',
    expandableRows = false,
    expandableRowExpanded = (row) => {},
    expandableRowsComponent,
    expandOnRowClicked = false,
    onRowExpandToggled = (expanded, row) => {},
    noTableHead = false,
    noDataComponent = null,
    conditionalRowStyles = [],
    sortServer = false,
    onSort = () => {},
    customStyles = {},
}) => {
    return (
        <div
            className={`data-table ${className} ${onRowClicked && 'clickable-rows'} ${
                headerBackground && 'header-bg'
            }`}
        >
            <ReactDataTable
                key={paginationPerPage}
                columns={columns}
                data={data}
                pagination={pagination}
                paginationServer={paginationServer}
                paginationTotalRows={paginationTotalRows}
                paginationPerPage={paginationPerPage}
                paginationDefaultPage={paginationDefaultPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                progressPending={progressPending}
                progressComponent={progressComponent}
                defaultSortFieldId={defaultSortFieldId}
                highlightOnHover={highlightOnHover}
                onRowClicked={onRowClicked ? onRowClicked : () => {}}
                persistTableHead={persistTableHead}
                expandableRows={expandableRows}
                expandableRowExpanded={expandableRowExpanded}
                expandableRowsComponent={expandableRowsComponent}
                expandOnRowClicked={expandOnRowClicked}
                onRowExpandToggled={onRowExpandToggled}
                noTableHead={noTableHead}
                noDataComponent={noDataComponent}
                conditionalRowStyles={conditionalRowStyles}
                sortServer={sortServer}
                onSort={onSort}
                customStyles={customStyles}
            />
        </div>
    );
};

DataTable.propTypes = {
    // https://react-data-table-component.netlify.app/?path=/docs/api-columns--page
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            selector: PropTypes.func,
            format: PropTypes.func,
            grow: PropTypes.number,
            width: PropTypes.string,
            minWidth: PropTypes.string,
            maxWidth: PropTypes.string,
            sortable: PropTypes.bool,
            style: PropTypes.object,
            cell: PropTypes.func,
            right: PropTypes.bool,
            center: PropTypes.bool,
            compact: PropTypes.bool,
            omit: PropTypes.bool,
            conditionalRowStyles: PropTypes.array,
        })
    ).isRequired,
    data: PropTypes.array,
    pagination: PropTypes.bool,
    paginationServer: PropTypes.bool,
    paginationTotalRows: PropTypes.number,
    paginationRowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    onChangeRowsPerPage: PropTypes.func,
    onChangePage: PropTypes.func,
    progressPending: PropTypes.bool,
    progressComponent: PropTypes.node,
    defaultSortFieldId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    highlightOnHover: PropTypes.bool,
    onRowClicked: PropTypes.func,
    persistTableHead: PropTypes.bool,
    expandableRows: PropTypes.bool,
    expandableRowExpanded: PropTypes.func,
    expandableRowsComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    expandOnRowClicked: PropTypes.bool,
    onRowExpandToggled: PropTypes.func,
    noTableHead: PropTypes.bool,
    noDataComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    sortServer: PropTypes.bool,
    onSort: PropTypes.func,
};

export default DataTable;
