import './UnifyResults.css';

// Libraries
import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

// Utils
import {
    GET_UNIFIED_RESULTS_FOR_TABLE,
    UNIFY_UNIFIED_RESULTS,
    useMutation,
    IGNORE_UNIFIED_RESULTS,
    useLazyQuery,
} from 'utils/graphql';
import { formatNumber, ucWords } from 'utils/text.util';

//Assets
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Loader from 'components/Loader/Loader';
import DataTable from 'components/DataTable/DataTable';
import Button from 'components/Buttons/Button/Button';
import Input from 'components/Form/Input/Input';
import UnifyOrIgnoreModal from 'components/Unify/UnifyOrIgnoreModal/UnifyOrIgnoreModal';
import Modal from 'components/Modal/Modal';

const UnifyResults = () => {
    let { table_id } = useParams();
    const [searchParams] = useSearchParams();
    const [table, setTable] = useState();
    const [minThreshold, setMinThreshold] = useState(0);
    const [title, setTitle] = useState('Unified Results');
    const [unifiedResults, setUnifiedResults] = useState([]);
    const [isUnifyIgnoreModalOpen, setIsUnifyIgnoreModalOpen] = useState(false);
    const [unifiedResultsToMerge, setUnifiedResultsToMerge] = useState([]);
    const [infoModalType, setInfoModalType] = useState();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [getUnifiedResults, { refetch: refetchResults, loading: isLoadingResults }] =
        useLazyQuery(GET_UNIFIED_RESULTS_FOR_TABLE, {
            fetchPolicy: 'cache-and-network',
            variables: {
                tableId: table_id,
                limit: perPage,
                offset: (page - 1) * perPage,
            },
            onCompleted: (data) => {
                const results = data.unifiedResultsForTable;
                setTable(results.table);
                setTitle(`Unified Results - ${ucWords(results.table.name)}`);
                if (results.success) {
                    setCount(results.count);
                    setMinThreshold(results.min_threshold);
                    setUnifiedResults(
                        results.unified_results.map((group) => {
                            return group.map((result) => {
                                const sortedAttributes = [...result.unified_result_attributes].sort(
                                    (a, b) => {
                                        // Sort by weight in descending order
                                        if (b.weight !== a.weight) {
                                            return b.weight - a.weight;
                                        }
                                        // If weights are the same, sort by field in ascending order
                                        return a.field.localeCompare(b.field);
                                    }
                                );

                                return {
                                    ...result,
                                    unified_result_attributes: sortedAttributes,
                                };
                            });
                        })
                    );
                } else {
                    setUnifiedResults([]);
                    setError(<div className="results-error">{results.error_msg}</div>);
                }
                setIsLoading(false);
            },
        });

    const [unifyUnifiedResults, { loading: merging }] = useMutation(UNIFY_UNIFIED_RESULTS);

    const [ignoreUnifiedResults, { loading: ignoring }] = useMutation(IGNORE_UNIFIED_RESULTS);

    const showInfoModal = (type) => {
        setInfoModalType(type);
        setIsInfoModalOpen(true);
    };

    const getInfoModalOptions = () => {
        switch (infoModalType) {
            case 'mergeSuccess':
            case 'ignoreSuccess':
                return {
                    title: 'Success',
                    content: (
                        <p>
                            Unified results have been{' '}
                            {infoModalType.startsWith('merge') ? 'merged' : 'ignored'}.
                        </p>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'mergeError':
            case 'ignoreError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <p>
                            There was an error while{' '}
                            {infoModalType.startsWith('merge') ? 'merging' : 'ignoring'} the unified
                            results.
                        </p>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const merge = useCallback(
        async (unifiedResultsIds, minScore) => {
            setIsLoading(true);
            const { data, error } = await unifyUnifiedResults({
                variables: { unifiedResultsIds, minScore },
            });
            if (error || !data.unifyUnifiedResults?.success) {
                showInfoModal('mergeError');
                setIsLoading(false);
            } else {
                showInfoModal('mergeSuccess');
                setIsLoading(false);
                refetchResults();
            }
        },
        [unifyUnifiedResults, refetchResults]
    );

    const ignore = useCallback(
        async (unifiedResultsIds) => {
            setIsLoading(true);
            const { data: ignoreData, error: ignoreError } = await ignoreUnifiedResults({
                variables: { unifiedResultsIds },
            });
            setIsLoading(false);
            if (ignoreError || !ignoreData.ignoreUnifiedResults?.success) {
                showInfoModal('ignoreError');
                setIsLoading(false);
            } else {
                showInfoModal('ignoreSuccess');
                setIsLoading(false);
                refetchResults();
            }
        },
        [ignoreUnifiedResults, refetchResults]
    );

    const openUnifyIgnoreModal = (row) => {
        setUnifiedResultsToMerge(row);
        setIsUnifyIgnoreModalOpen(true);
    };

    const getColumns = useCallback(() => {
        return [
            {
                name: 'Unified Attributes',
                sortable: false,
                selector: (row, index) => {
                    return (
                        <div className="attr-row" key={index}>
                            {row[0].unified_result_attributes.map((attribute, i) => (
                                <div className="attr-col" key={i}>
                                    {index === 0 && (
                                        <div className="attr-name">
                                            {attribute.field} ({attribute.weight})
                                        </div>
                                    )}
                                    <div className="attr-value">
                                        <Input value={attribute.value} readOnly={true} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                name: 'Number of Matches',
                sortable: false,
                grow: 0,
                width: '160px',
                selector: (row) => {
                    return <div className="number-col">{formatNumber(row.length)}</div>;
                },
            },
            {
                name: 'Min Similarity Score',
                sortable: false,
                grow: 0,
                width: '150px',
                selector: (row) => {
                    return (
                        <div className="number-col">
                            {parseFloat(row[row.length - 1].score.toFixed(2))}
                        </div>
                    );
                },
            },
            {
                name: 'Action',
                sortable: false,
                width: '200px',
                selector: (row) => (
                    <div className="action-btns">
                        <Button
                            onClick={() =>
                                merge(
                                    row.map((unifiedResult) => unifiedResult.id),
                                    minThreshold
                                )
                            }
                            isDisabled={merging || ignoring}
                        >
                            Merge
                        </Button>
                        <Button
                            onClick={() => ignore(row.map((unifiedResult) => unifiedResult.id))}
                            isDisabled={merging || ignoring}
                            variant="secondary"
                        >
                            Ignore All
                        </Button>
                    </div>
                ),
            },
        ];
    }, [ignore, merge, merging, ignoring, minThreshold]);

    useEffect(() => {
        setIsLoading(true);
        let filter = null;
        let filterValue = null;
        let filterOp = null;
        if (searchParams) {
            filter = searchParams.get('filter', null);
            filterValue = searchParams.get('filter_value', null);
            filterOp = searchParams.get('filter_op', null);
        }

        // updating url params when page/perPage changes
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', page);
        urlParams.set('perPage', perPage);
        window.history.replaceState(null, '', `?${urlParams.toString()}`);
        setError(null);
        getUnifiedResults({
            variables: {
                limit: perPage,
                offset: (page - 1) * perPage,
                filter,
                filterValue,
                filterOp,
            },
        });
    }, [page, perPage, searchParams, getUnifiedResults]);

    return (
        <div className="unify-results">
            <ContentPanel title={title}>
                <div className="unify-results-container">
                    <DataTable
                        columns={getColumns()}
                        data={unifiedResults}
                        noDataComponent={
                            error || (
                                <div className="no-results">No unified results to display.</div>
                            )
                        }
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={perPage}
                        paginationDefaultPage={page}
                        paginationTotalRows={count}
                        onChangeRowsPerPage={(newPerPage) => setPerPage(newPerPage)}
                        onChangePage={(newPage) => setPage(newPage)}
                        onRowClicked={(row) => openUnifyIgnoreModal(row)}
                        highlightOnHover={false}
                        progressPending={isLoading || isLoadingResults}
                        progressComponent={
                            <div className="results-loader">
                                <Loader />
                            </div>
                        }
                        className="unify-results-table"
                        customStyles={{
                            rows: {
                                style: {
                                    backgroundColor: 'var(--greyscale-color)',
                                },
                            },
                            table: {
                                style: {
                                    backgroundColor: 'var(--greyscale-color)',
                                },
                            },
                        }}
                    />
                </div>
            </ContentPanel>
            <UnifyOrIgnoreModal
                minThreshold={minThreshold}
                isOpen={isUnifyIgnoreModalOpen}
                setIsOpen={setIsUnifyIgnoreModalOpen}
                unifiedResults={unifiedResultsToMerge}
                table={table?.name}
                ignore={ignore}
                merge={merge}
            />
            <Modal
                isOpen={isInfoModalOpen}
                setOpen={setIsInfoModalOpen}
                options={getInfoModalOptions()}
            />
        </div>
    );
};

export default UnifyResults;
